import * as Dialog from "@radix-ui/react-dialog"
import { cn } from "~/lib/cva"
import { LinkInfo } from "~/types"
import { LinkWithLang } from "~/ui"
import Logo from "~/ui/Logo"
import * as React from "react"
import { MdMenu } from "react-icons/md"
import { useParams } from "react-router-dom"
import { ThemeToggler } from "../../ThemeToggler"
import { StyledLink } from "../Header"
import LanguageMenu from "./LanguageMenu"

type DrawerProps = {
  links: LinkInfo[]
  isScrolling: boolean
}

export const Drawer = ({ links, isScrolling }: DrawerProps) => {
  const { lang } = useParams()
  const [open, setOpen] = React.useState(false)

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button
          className="mx-2"
          type="button"
          aria-label="Open menu"
          data-testid="header-appbar-btn-drawer"
        >
          <MdMenu
            className={cn("size-8 text-black dark:text-white", {
              "text-black dark:text-white": isScrolling,
            })}
          />
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-30 bg-[#ccc] opacity-0 !duration-1000 !fade-in-30 !fade-out-30 fill-mode-both data-[state=closed]:animate-in data-[state=open]:animate-out" />
        <Dialog.Content
          aria-describedby={undefined}
          className={cn(
            "fixed top-0 z-40 flex h-screen w-[288px] flex-col justify-center overflow-y-auto border border-gray-300 px-4 pb-16",
            "bg-light-card backdrop-blur-[20px] dark:bg-dark-card",
            lang === "en"
              ? "left-0 -translate-x-full !duration-1000 fill-mode-both data-[state=closed]:animate-in data-[state=open]:animate-out"
              : "right-0 translate-x-full !duration-1000 fill-mode-both data-[state=closed]:animate-in data-[state=open]:animate-out"
          )}
        >
          <Dialog.Title className="sr-only">main menu</Dialog.Title>
          <div className="logo-container flex justify-between">
            <div className="flex w-full justify-center py-4">
              <LinkWithLang to="/" data-testid="header-appbar-btn-logo">
                <Logo width="100%" height="100%" />
              </LinkWithLang>
            </div>
          </div>
          <div className="flex flex-col items-start">
            {links.map((link, index) => (
              <div key={index} className="my-2">
                <Dialog.Close asChild>
                  <StyledLink
                    to={link.path}
                    icon={link.icon}
                    lang={lang}
                    label={link.label}
                    data-testid={`header-appbar-btn-${
                      link.path === "/" ? "home" : link.path
                    }`}
                  />
                </Dialog.Close>
              </div>
            ))}
            <div className="mt-4 flex items-center justify-center gap-4">
              <ThemeToggler />
              <LanguageMenu />
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
