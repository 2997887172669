import { useMediaQuery } from "@mantine/hooks"
import { useScrollDir } from "~/lib/framer-motion"
import { CSSProperties, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  LinkProps,
  NavLink,
  useLocation,
  useParams,
} from "react-router-dom"
import { screens } from "tailwindcss/defaultTheme"
import { NavLinks } from "../../../config/linksDetails"
import { cn } from "../../../lib/cva"
import { LinkWithLang } from "../../../ui"
import Logo from "../../../ui/Logo"
import { ThemeToggler } from "../ThemeToggler"
import { Drawer } from "./components"
import LanguageMenu from "./components/LanguageMenu"

export const Header = () => {
  const [isAppBarVisible, setIsAppBarVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)

  const isInitialPosition = useMemo(() => lastScrollY === 0, [lastScrollY])
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (currentScrollY > lastScrollY && currentScrollY > 400) {
        setIsAppBarVisible(false)
      } else {
        setIsAppBarVisible(true)
      }
      setLastScrollY(currentScrollY)
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [lastScrollY])

  const isMediumScreen = useMediaQuery(`(min-width: ${screens.md})`)
  const { lang } = useParams()
  const dir = useScrollDir({
    HoldDelayValue: 0,
    initialScrollValue: 400,
  })

  const location = useLocation()
  const isHomePage = location.pathname === `/${lang}`
  return (
    <header
      className={cn(
        `fixed left-0 top-0 z-40 flex h-20 w-full items-center bg-transparent text-black transition-[transform_colors] duration-500`,
        {
          "translate-y-0": isAppBarVisible,
          "-translate-y-full shadow-[0px_2px_8px_rgba(0,0,0,0.1)]": !isAppBarVisible,
          "bg-light-card dark:bg-dark-card": !isInitialPosition,
        }
      )}
    >
      <nav
        className={`container relative flex items-center justify-between max-md:max-w-screen-md max-md:px-4`}
      >
        {isMediumScreen && (
          <>
            <div className="flex grow-[1]">
              <div className="flex min-w-[75px] justify-end">
                <LinkWithLang to={`/`} data-testid={`header-appbar-btn-logo`}>
                  <Logo className="text-black dark:text-white" />
                </LinkWithLang>
              </div>
              <div className="grow-[1]"></div>
            </div>
            <div className="grid grow-[2] place-items-center justify-between delay-200 ease-in">
              <div className="flex gap-4 transition-drawer">
                {NavLinks.map((link, index) =>
                  link.protected ? (
                    <StyledLink
                      key={index}
                      to={link.path}
                      icon={link.icon}
                      isHomePage={isHomePage}
                      label={link.label}
                      data-testid={`header-appbar-btn-${
                        link.path === "/" ? "home" : link.path
                      }`}
                    />
                  ) : (
                    <StyledLink
                      key={index}
                      to={link.path}
                      icon={link.icon}
                      isHomePage={isHomePage}
                      label={link.label}
                      data-testid={`header-appbar-btn-${
                        link.path === "/" ? "home" : link.path
                      }`}
                    />
                  )
                )}
              </div>
            </div>
            <ThemeToggler />
            <LanguageMenu />
          </>
        )}
        {!isMediumScreen && (
          <>
            <LinkWithLang to="/">
              <Logo className="text-black dark:text-white" />
            </LinkWithLang>
            <Drawer links={NavLinks} isScrolling={dir === 1 || dir === -1} />
          </>
        )}
      </nav>
    </header>
  )
}
type LinkComponentProps = LinkProps & {
  to: string
  style?: CSSProperties | undefined
  active?: boolean
  icon: React.ReactNode
  label: string
  isHomePage?: boolean
}
export const StyledLink = ({
  active,
  icon,
  label,
  to,
  isHomePage = false,
  ...props
}: LinkComponentProps) => {
  const { t } = useTranslation()
  const { lang } = useParams()

  const navLinkSwitcher = (link: string) => {
    if (link === `/`) return `/${lang}`
    if (link === `/` && isHomePage) return `#`
    const currentLink = isHomePage && link!=="careers" ? `#${link}-section` : link
    return currentLink
  }
  const href = navLinkSwitcher(to)
  return (
    <NavLink
      to={href}
      preventScrollReset={false}
      className={({ isActive }) =>
        cn(
          "group mx-[10px] flex items-center justify-center gap-2 font-normal text-light-main_label transition-transform duration-300 hover:!text-[#e76712] dark:text-dark-main_label",
          {
            "font-bold": href.startsWith("#")
              ? false
              : isHomePage
                ? to === "/"
                : isActive,
          }
        )
      }
      end
      {...props}
    >
      {icon}
      <span className={cn(`whitespace-nowrap text-[18px] text-inherit`)}>
        {t(label)}
      </span>
    </NavLink>
  )
}
