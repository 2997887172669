export const LoadingSpinner = () => {
  return (
    <img
      src="/loading.gif"
      width={100}
      height={100}
      sizes="100px"
      alt="Loading"
    />
  )
}
