import i18next from "i18next"
import Languagedetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"

export const languages = ["en", "ar"] as const
i18next.use(initReactI18next).use(Languagedetector).use(Backend).init({
  debug: false,
  fallbackLng: languages[0],
})
