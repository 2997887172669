import { ElementRef, forwardRef } from "react"
import { Link, LinkProps, useParams } from "react-router-dom"

export const LinkWithLang = forwardRef<ElementRef<"a">, LinkProps>(
  ({ to, ...props }, forwardedRef) => {
    const { lang } = useParams()
    const href = to !== "/" ? `/${lang}${to}` : `/${lang}`
    return (
      <Link ref={forwardedRef} to={href} {...props}>
        {props.children}
      </Link>
    )
  }
)
