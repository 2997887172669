import { cn } from "~/lib/cva"
import * as React from "react"

const Logo = ({
  shadowColor,
  className,
  ...props
}: React.ComponentPropsWithoutRef<"svg"> & {
  shadowColor?: string
}) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000.000000 707.000000"
      preserveAspectRatio="xMidYMid meet"
      height={72}
      width={72}
      filter={`drop-shadow(1px 1px 2px ${shadowColor})`}
      className={cn("text-light-primary", className)}
      {...props}
    >
      <metadata>Logo</metadata>
      <g
        transform="translate(0.000000,707.000000) scale(0.100000,-0.100000)"
        stroke="none"
        className={`fill-current`}
      >
        <path
          d="M4975 6848 c-235 -132 -2117 -1257 -2132 -1274 l-23 -25 0 -1116 0
-1116 -40 -35 c-61 -52 -84 -103 -84 -187 -1 -80 19 -129 71 -181 46 -47 87
-65 153 -71 108 -10 208 46 255 142 31 63 27 167 -7 225 -14 25 -38 56 -52 69
l-26 24 0 1062 0 1062 957 567 c813 483 961 567 987 564 37 -5 1785 -1000
1804 -1027 7 -10 10 -25 7 -33 -5 -13 -396 -264 -436 -280 -18 -7 -87 30 -746
407 l-349 200 -12 47 c-44 188 -265 261 -408 135 -83 -73 -111 -170 -79 -268
25 -73 94 -142 166 -163 66 -20 91 -20 155 2 l51 18 594 -339 c327 -187 603
-342 614 -344 23 -5 854 511 893 553 31 36 29 84 -5 115 -16 14 -523 308
-1128 652 -785 447 -1109 627 -1130 627 -17 0 -39 -6 -50 -12z"
        />
        <path
          d="M3710 5290 c-115 -24 -195 -111 -207 -223 -9 -90 42 -200 111 -237
l26 -13 0 -1136 c0 -625 4 -1141 8 -1148 11 -18 1340 -774 1390 -791 34 -12
51 -12 81 -3 36 10 2147 1257 2183 1288 25 22 32 67 15 100 -20 38 -964 577
-992 566 -11 -3 -269 -155 -574 -336 l-554 -329 -61 13 c-245 48 -406 -249
-230 -425 99 -99 251 -100 352 -3 43 41 63 79 76 146 l11 53 485 289 c292 173
495 288 510 288 14 0 133 -62 265 -137 228 -131 240 -139 240 -167 0 -29 -28
-46 -830 -523 -849 -504 -914 -542 -938 -542 -15 0 -1132 634 -1159 658 -17
14 -18 78 -18 1082 l0 1067 40 43 c65 70 85 163 54 252 -39 117 -168 192 -284
168z"
        />
        <path
          d="M3395 1182 c-53 -33 -36 4 -246 -521 -72 -178 -128 -329 -125 -337 4
-10 26 -14 80 -14 87 0 88 1 126 107 l22 62 126 3 c154 4 157 7 121 100 l-25
63 -76 3 -76 3 22 57 c13 31 32 80 43 107 12 28 32 79 45 113 l25 64 135 -339
135 -338 77 -3 c62 -2 77 0 82 13 5 13 -243 650 -308 791 -33 73 -120 104
-183 66z"
        />
        <path
          d="M574 1180 c-12 -4 -31 -21 -43 -36 -21 -26 -21 -34 -21 -402 l0 -375
30 -28 30 -29 286 0 c329 0 333 1 375 88 20 40 23 62 23 158 0 101 -2 117 -26
162 -21 41 -24 53 -13 62 24 20 38 97 33 192 -3 75 -8 102 -28 138 -41 73 -67
79 -370 79 -140 0 -265 -4 -276 -9z m506 -179 c14 -27 13 -134 -2 -149 -8 -8
-52 -12 -133 -12 -84 0 -127 -4 -139 -13 -22 -16 -32 -91 -17 -129 l11 -28
125 0 c70 0 135 -5 146 -10 16 -9 19 -22 19 -91 0 -76 -1 -80 -26 -89 -14 -6
-106 -10 -205 -10 l-179 0 0 275 0 275 195 0 c182 0 195 -1 205 -19z"
        />
        <path
          d="M1370 1171 c-14 -27 -13 -114 2 -129 17 -17 639 -17 656 0 15 15 16
102 2 129 -10 18 -25 19 -330 19 -305 0 -320 -1 -330 -19z"
        />
        <path
          d="M2162 1178 c-7 -7 -12 -40 -12 -79 0 -81 -5 -79 170 -79 l125 0 -3
-339 c-3 -391 -8 -371 87 -371 31 0 62 5 69 12 9 9 12 102 12 358 l0 345 130
-3 c165 -4 170 -1 170 81 0 36 -5 68 -12 75 -17 17 -719 17 -736 0z"
        />
        <path
          d="M4674 1176 c-150 -36 -187 -131 -181 -464 4 -222 17 -280 79 -339 61
-57 63 -58 333 -61 229 -3 250 -2 262 14 16 22 17 116 1 132 -9 9 -71 12 -219
12 -118 0 -219 4 -236 10 -49 17 -53 41 -53 276 0 215 0 215 25 239 l24 25
219 0 c254 0 252 -1 252 88 0 83 2 82 -243 81 -131 0 -231 -5 -263 -13z"
        />
        <path
          d="M5464 1176 c-150 -37 -186 -130 -181 -464 3 -176 5 -203 25 -254 25
-61 83 -122 129 -135 16 -5 127 -8 249 -8 l221 0 48 30 c90 56 115 143 115
404 0 266 -27 354 -123 407 -46 25 -62 27 -252 30 -123 2 -196 -1 -231 -10z
m405 -173 c32 -26 44 -128 39 -308 -6 -166 -15 -198 -61 -215 -39 -13 -305
-13 -345 1 -49 17 -54 43 -50 284 4 277 -15 255 223 255 150 0 176 -2 194 -17z"
        />
        <path
          d="M6192 1178 c-7 -7 -12 -40 -12 -79 0 -61 2 -67 23 -73 12 -3 131 -6
263 -6 278 0 268 3 269 -91 0 -96 8 -93 -293 -99 l-257 -5 0 -255 0 -255 63
-3 c38 -2 69 2 78 9 11 9 14 47 14 180 l0 169 109 0 108 0 100 -178 100 -177
80 -3 c120 -4 121 -11 -17 224 -82 138 -85 145 -72 143 47 -8 112 51 135 122
34 104 15 264 -39 328 -44 53 -94 61 -384 61 -186 0 -259 -3 -268 -12z"
        />
        <path
          d="M7052 1178 c-7 -7 -12 -39 -12 -75 0 -82 5 -85 170 -81 l130 3 0
-345 c0 -256 3 -349 12 -358 12 -12 104 -17 132 -6 14 5 16 46 16 355 l0 349
131 0 c72 0 139 5 150 10 16 9 19 22 19 73 0 36 -5 68 -12 75 -17 17 -719 17
-736 0z"
        />
        <path
          d="M7920 1171 c-11 -22 -13 -83 -4 -118 l6 -23 318 0 c175 0 325 3 334
6 20 8 23 123 4 142 -9 9 -96 12 -330 12 -303 0 -318 -1 -328 -19z"
        />
        <path
          d="M8731 1171 c-9 -16 5 -40 82 -148 51 -70 112 -156 136 -190 l43 -61
-43 -64 c-24 -35 -92 -132 -152 -216 -128 -181 -128 -184 -8 -180 l76 3 103
145 c57 80 108 152 113 160 6 8 12 11 15 5 2 -5 53 -77 112 -160 l107 -150 83
-3 c67 -2 83 0 88 13 5 12 -35 88 -69 130 -59 73 -215 311 -210 319 11 17 159
222 221 305 70 92 66 105 -34 109 -38 1 -74 -1 -80 -5 -11 -8 -188 -244 -204
-272 -11 -20 -9 -22 -105 119 -113 166 -105 160 -191 160 -62 0 -75 -3 -83
-19z"
        />
        <path
          d="M1397 804 c-32 -32 -42 -89 -34 -195 13 -159 50 -233 140 -274 46
-22 62 -23 282 -24 270 -2 255 -8 255 103 0 99 7 96 -246 96 -215 0 -215 0
-239 25 -21 20 -25 34 -25 80 l0 55 245 0 245 0 5 23 c4 13 5 48 3 78 l-3 54
-301 3 -301 2 -26 -26z"
        />
        <path
          d="M7948 805 c-22 -21 -26 -37 -31 -107 -14 -184 31 -315 120 -360 46
-22 60 -23 284 -26 284 -4 273 -9 267 105 -2 51 -8 77 -18 83 -8 5 -107 10
-220 10 -113 0 -215 3 -226 6 -29 8 -44 44 -44 104 l0 50 245 0 245 0 5 23 c4
13 5 48 3 78 l-3 54 -301 3 -301 2 -25 -25z"
        />
      </g>
    </svg>
  )
}
export default Logo
