import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

export const FooterLink = ({
  href,
  label,
}: {
  href: string
  label: string
}) => {
  const { t } = useTranslation()
  const { lang } = useParams()
  return (
    <Link
      className="capitalize transition-colors hover:text-light-secondary dark:hover:text-dark-secondary"
      to={href === "/" ? `/${lang}/${href}` : href}
    >
      {t(label)}
    </Link>
  )
}
