import { useTranslation } from "react-i18next"
import { IconBaseProps } from "react-icons/lib"
import { Link } from "react-router-dom"
import { Icon, IconName } from "../../../ui"
import Logo from "../../../ui/Logo"
import { FooterLink } from "./FooterLink"

const lowBarLinks = [
  {
    name: "footer.Privacy_Policy",
    href: "privacy-policy",
  },
  {
    name: "footer.Terms_of_Use",
    href: "term-of-service",
  },
  {
    name: "footer.home",
    href: "/",
  },
  {
    name: "footer.about_us",
    href: "about-us",
  },
  {
    name: "our_services",
    href: "services",
  },
  {
    name: "footer.contact_us",
    href: "contact-us",
  },
]
const socialLinks = [
  {
    name: "facebook",
    href: "https://www.facebook.com/profile.php?id=61564673294374&mibextid=ZbWKwL",
  },
  {
    name: "twitter",
    href: "https://twitter.com/Beta_Cortex",
  },
  {
    name: "instagram",
    href: "https://www.instagram.com/beta.cortex?igsh=dXl4ZW9nZWlsejFp",
  },
  {
    name: "whatsapp",
    href: "https://wa.me/963937094356",
  },
  {
    name: "telegram",
    href: "https://t.me/betacortex",
  },
] as const
const date = new Date()
const copyrights = `© ${date.getFullYear()} Beta Cortex`
export const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className="relative overflow-hidden text-dark-text dark:text-light-text">
      <svg
        width="2054"
        height="150"
        viewBox="0 0 2054 100"
        fill="none"
        className="text-light-primary drop-shadow-[0px_-6px_4px_rgb(37,37,37,0.4)] filter dark:text-dark-primary dark:drop-shadow-[0px_-6px_4px_rgb(153,153,153,0.3)]"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12.8401L118.426 4.2733C162.427 1.09037 206.655 3.74463 249.959 12.1669L724.112 104.386C779.202 115.101 835.702 116.464 891.244 108.419L1604.12 5.16408C1673.15 -4.83464 1743.51 -0.279222 1810.68 18.5375L2054 86.7052V473H0V12.8401Z"
          fill="currentColor"
        />
      </svg>
      <div className="bg-light-primary dark:bg-dark-primary">
        <div className="container flex flex-col items-center justify-center gap-4 md:flex-row md:justify-between md:gap-8 md:px-16">
          <Logo
            width={220}
            height={220}
            className="text-white dark:text-white"
            shadowColor="#00000099"
          />

          <div className="flex items-center justify-center gap-4 py-2">
            {socialLinks.map((social, index) => {
              const SocialIcon = (props: IconBaseProps) =>
                Icon[social.name as IconName](props)
              return (
                <Link
                  key={index}
                  target="_blank"
                  className="flex size-10 items-center justify-center rounded-full capitalize transition-colors hover:bg-white/10 hover:text-light-secondary dark:hover:bg-white/10 dark:hover:text-dark-secondary"
                  to={social.href}
                >
                  <SocialIcon className="size-6" />
                  <span className="sr-only">{t(`footer.${social.name}`)}</span>
                </Link>
              )
            })}
          </div>
        </div>
        <ul className="flex flex-col items-center justify-center gap-4 py-4 text-center md:flex-row">
          {lowBarLinks.map((link, index) => {
            return (
              <li key={index}>
                <FooterLink href={link.href} label={link.name} />
              </li>
            )
          })}
        </ul>

        <div
          style={{ direction: "ltr" }}
          className="border-t-1 dark:border-t-dark-bg"
        >
          <div className="container mx-auto grid max-w-screen-xl grid-cols-2 p-4">
            <p className="mx-auto">{copyrights}</p>
            <Link dir="ltr" className="mx-auto" to={`tel:+963937094356`}>
              +963 937 094 356
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
