import { ComponentProps, ElementRef, forwardRef, useId } from "react";
import { ErrorMsgWrapper } from "./ErrorMsgWrapper";
import { FormInput } from "./type";
import classNames from "classnames";

export const Textarea = forwardRef<
	ElementRef<"textarea">,
	FormInput & ComponentProps<"textarea">
>(({ label, errorMSG = "", ...props }, forwardedRef) => {
	const id = useId();

	return (
		<ErrorMsgWrapper errorMSG={errorMSG}>
			<textarea
				ref={forwardedRef}
				id={id}
				className={classNames(
					"form-textarea bg-neutral-white text-neutral-black",
					{
						"border-0 ring-[1px] ring-danger-500 dark:text-danger-600":
							Boolean(errorMSG),
						"h-28 w-full resize-none rounded bg-light-bg dark:bg-dark-bg p-2 placeholder:capitalize":
							true,
					}
				)}
				placeholder={label}
				cols={30}
				rows={8}
				{...props}
			/>
		</ErrorMsgWrapper>
	);
});
Textarea.displayName = "Textarea";
