import { MdLightMode, MdOutlineDarkMode } from "react-icons/md"
import { useThemeToggle } from "../../contexts/ThemeProvider"
import { cn } from "../../lib/cva"

export const ThemeToggler = () => {
  const { theme, toggleTheme } = useThemeToggle()

  return (
    <button
      className={cn(
        "rounded border-none p-2 transition-colors duration-300 hover:bg-gray-200 dark:hover:bg-gray-700",
        "[&>svg]:text-light-main_label [&>svg]:dark:text-dark-main_label"
      )}
      onClick={toggleTheme}
      data-testid={`header-appbar-btn-theme`}
    >
      {theme === "light" ? (
        <MdLightMode size={24} className="text-black" />
      ) : (
        <MdOutlineDarkMode size={24} className="text-white" />
      )}
      <span className="sr-only">
        {theme === "light" ? "switch to dark mode" : "switch to light mode"}
      </span>
    </button>
  )
}
