import { LinkWithLang } from "~/ui"
import i18next from "i18next"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { FaRepeat } from "react-icons/fa6"
import { IoMdArrowRoundBack } from "react-icons/io"
import { IoCloudOfflineOutline } from "react-icons/io5"

export const ErrorBoundaryLayout = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <ErrorBoundary
    fallbackRender={({ resetErrorBoundary, error }) => (
      <FallbackBox
        errorMessage={error.message}
        onClick={() => resetErrorBoundary()}
      />
    )}
  >
    {children}
  </ErrorBoundary>
)

const FallbackBox = ({
  // errorMessage,
  onClick,
}: {
  errorMessage: string
  onClick: () => void
}) => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="mx-4 flex w-full max-w-[600px] flex-col items-center justify-center gap-2 rounded-md bg-light-card py-[10px] text-black shadow-md hover:shadow-lg dark:bg-dark-card">
        <h2 className="text-[20px] font-semibold uppercase text-light-text dark:text-dark-text">
          {i18next.t("utils.service_not_available")}
        </h2>
        <IoCloudOfflineOutline
          size={200}
          className="text-light-text dark:text-dark-text"
        />

        <div className="mx-auto my-[7px] box-border h-[2px] w-11/12 bg-gradient-to-l from-violet-400 via-cyan-500 to-purple-400"></div>
        <div className="flex flex-col">
          <button
            onClick={() => onClick()}
            className="duration-250 flex items-center justify-center gap-1 rounded-sm p-[5px] font-bold no-underline transition-all hover:bg-light-primary hover:shadow-sm hover:ring-1 dark:hover:bg-dark-primary"
          >
            <FaRepeat className="text-light-text dark:text-dark-text" />
            <div className="text-light-text dark:text-dark-text">
              {i18next.t("utils.try_again")}
            </div>
          </button>

          <LinkWithLang
            to={`/`}
            className="duration-250 flex items-center gap-1 rounded-sm p-[5px] font-bold no-underline transition-all hover:bg-light-primary hover:shadow-sm hover:ring-1 dark:hover:bg-dark-primary"
          >
            <IoMdArrowRoundBack className="rtl:rotate-180" />
            {i18next.t("utils.back_to_home_page")}
          </LinkWithLang>
        </div>
      </div>
    </div>
  )
}
