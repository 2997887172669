import classNames from "classnames"
import { ComponentProps, ElementRef, forwardRef, useId } from "react"
import { ErrorMsgWrapper } from "./ErrorMsgWrapper"
import { FormInput } from "./type"

export const Input = forwardRef<
  ElementRef<"input">,
  FormInput & ComponentProps<"input">
>(
  (
    { label, errorMSG = "", type = "text", className, ...props },
    forwardedRef
  ) => {
    const id = useId()

    return (
      <ErrorMsgWrapper errorMSG={errorMSG}>
        <input
          ref={forwardedRef}
          id={id}
          className={classNames(
            "form-input bg-light-bg text-light-text dark:bg-dark-bg dark:text-dark-text",
            {
              "border-0 ring-[1px] ring-rose-500 dark:ring-rose-600":
                Boolean(errorMSG),
              "h-10 w-full rounded bg-light-bg px-4 placeholder:capitalize dark:bg-dark-bg":
                ["text", "email", "password", "number"].includes(type),
            }
          )}
          placeholder={label}
          type={type}
          {...props}
        />
      </ErrorMsgWrapper>
    )
  }
)
Input.displayName = "Input"
