import { useLocalStorage } from "@mantine/hooks"
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react"

const Context = createContext<{
  toggleTheme: () => void
  theme: "dark" | "light" | null
}>({
  toggleTheme: () => {},
  theme: null,
})

export const useThemeToggle = () => useContext(Context)

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const darkTheme = "dark"
  const lightTheme = "light"

  const [theme, setTheme] = useLocalStorage<"dark" | "light">({
    key: "theme",
    defaultValue: lightTheme,
  })

  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      document.documentElement.classList.toggle(
        "dark",
        prevTheme === lightTheme
      )
      return prevTheme === lightTheme ? darkTheme : lightTheme
    })
  }, [setTheme])

  useLayoutEffect(() => {
    const root = window.document.documentElement
    if (theme === darkTheme) {
      root.classList.add(darkTheme)
    } else {
      root.classList.remove(darkTheme)
    }
  }, [theme])

  const value = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme])
  return <Context.Provider value={value}>{children}</Context.Provider>
}
