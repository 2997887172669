import { SVGProps } from "react"
import {
  BsChevronBarDown,
  BsEnvelope,
  BsFacebook,
  BsGeoAltFill,
  BsGithub,
  BsTelephone,
  BsTelephoneFill,
} from "react-icons/bs"
import {
  FaBullhorn,
  FaCheck,
  FaDesktop,
  FaExclamationTriangle,
  FaLightbulb,
  FaLock,
  FaPaintBrush,
  FaRocket,
  FaShareAlt,
  FaShieldAlt,
  FaSitemap,
  FaTachometerAlt,
  FaThumbtack,
  FaUserFriends,
} from "react-icons/fa"
import { FaSquareXTwitter } from "react-icons/fa6"
import { LiaTelegram } from "react-icons/lia"
import { RiInstagramFill, RiWhatsappFill } from "react-icons/ri"

export type IconType = JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>

export const Icon = {
  googlePin: BsGeoAltFill,
  telephone: BsTelephone,
  envelope: BsEnvelope,
  facebook: BsFacebook,
  instagram: RiInstagramFill,
  github: BsGithub,
  twitter: FaSquareXTwitter,
  whatsapp: RiWhatsappFill,
  telegram: LiaTelegram,
  cheveron: BsChevronBarDown,
  check: FaCheck,
  deskTop: FaDesktop,
  bullhorn: FaBullhorn,
  lightbulb: FaLightbulb,
  lock: FaLock,
  paintBrush: FaPaintBrush,
  rocket: FaRocket,
  shareAlt: FaShareAlt,
  shieldAlt: FaShieldAlt,
  sitemap: FaSitemap,
  tachometerAlt: FaTachometerAlt,
  thumbtack: FaThumbtack,
  userFriends: FaUserFriends,
  vulnerability: FaExclamationTriangle,
  countryTelePhone: BsTelephoneFill,
}

export type IconName = keyof typeof Icon
