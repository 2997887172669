import { GrContactInfo } from "react-icons/gr"
import { ImProfile } from "react-icons/im"
import {
  MdContactSupport,
  MdOutlineMiscellaneousServices,
} from "react-icons/md"
import { SiHiveBlockchain } from "react-icons/si"
import { LinkInfo, SideBarLinkInfo } from "../types"
import { FaBusinessTime } from "react-icons/fa"

export const NavLinks: Array<LinkInfo> = [
  { label: "home", path: "/", protected: false, icon: <SiHiveBlockchain /> },
  {
    label: "our_services",
    path: "services",
    protected: false,
    icon: <MdOutlineMiscellaneousServices />,
  },
  {
    label: "about_us",
    path: "about-us",
    protected: false,
    icon: <GrContactInfo />,
  },
  {
    label: "careers_link",
    path: "careers",
    protected: false,
    icon: <FaBusinessTime />,
  },
  {
    label: "contact_us",
    path: "contact-us",
    protected: false,
    icon: <MdContactSupport />,
  },
]

export const sideBarLinks: Array<SideBarLinkInfo> = [
  { label: "Profile", path: "profile", icon: <ImProfile /> },
]
export const contactLinks = {
  email: {
    name: "info@betacortex.net",
    href: "mailto:info@betacortex.net",
  },

  syrianPhone: {
    name: "SY : +963 937094356",
    href: "tel:+963937094356",
  },
  UAEPhone: {
    name: "UAE : +971 508040246",
    href: "tel:+971508040246",
  },
}
export const location = {
  name: "syria tartus",
  href: "",
}
