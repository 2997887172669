import { ReactNode } from "react"

type ErrorMsgWrapperProps = {
  children?: ReactNode
  errorMSG?: string
}

export const ErrorMsgWrapper = (props: ErrorMsgWrapperProps) => {
  return (
    <div className="relative flex flex-col justify-start pb-5 ">
      {props.children}
      {props.errorMSG && (
        <span className="overflow-hidden whitespace-nowrap text-ellipsis absolute bottom-0 flex w-full items-center ps-1 text-sm leading-5 text-rose-500 dark:text-rose-600">
          {props.errorMSG}
        </span>
      )}
    </div>
  )
}
