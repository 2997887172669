import * as Select from "@radix-ui/react-select"
import { cn } from "~/lib/cva"
import { Icon } from "~/ui"
import { useTranslation } from "react-i18next"
import { MdOutlineLanguage } from "react-icons/md"
import { useLocation, useNavigate } from "react-router-dom"

const languages = ["en", "ar"] // Replace with your actual language codes

export default function LanguageMenu() {
  const [t, i18n] = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname.slice(3)
  const currentLang = i18n.language

  const handleValueChange = (value: string) => {
    i18n.changeLanguage(value)
    navigate(`/${value}${currentPath}`)
  }

  return (
    <Select.Root
      // open={true}
      value={currentLang}
      onValueChange={handleValueChange}
    >
      <Select.Trigger
        className={cn(
          "inline-flex items-center justify-center rounded border-none p-2 transition-colors duration-300 hover:bg-gray-200 dark:hover:bg-gray-700",
          "text-light-main_label dark:text-dark-main_label"
        )}
        aria-label="Select language"
        data-testid="header-appbar-btn-language"
      >
        <MdOutlineLanguage size={22} />
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          className="relative z-50 overflow-hidden rounded-md bg-white shadow-md dark:bg-gray-800"
          position="popper"
          sideOffset={5}
        >
          <Select.Viewport className="">
            {languages.map((lang) => (
              <Select.Item
                key={lang}
                value={lang}
                className={cn(
                  "relative flex cursor-pointer items-center px-6 py-2 text-sm text-light-primary",
                  "rounded-sm outline-none transition-colors",
                  "hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700",
                  "data-[highlighted]:bg-gray-200 data-[highlighted]:dark:bg-gray-700",
                  "data-[state=checked]:font-medium"
                )}
                data-testid={`header-appbar-btn-${lang === "ar" ? "arabic" : "english"}`}
              >
                <Select.ItemText>{t(lang)}</Select.ItemText>
                <Select.ItemIndicator className="absolute left-2 inline-flex items-center">
                  <Icon.check />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}
